import {NextPage, NextPageContext} from 'next';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {LoginDisabled} from '../client/auth/components/LoginDisabled';
import {isLogged} from '../client/auth/service/LoginService';
import {useCommonAction} from '../client/common/action/CommonAction';
import {Div} from '../client/common/styledComponents/Div';
import {apolloClient} from '../client/lib/apolloClient';
import {Store} from '../client/lib/StoreType';

type Props = {
    readonly statusCode: number;
};

const Index: NextPage<Props> = () => {
    const {errorLogin, errorLoginConfig, loginSuccess} = useSelector((state: Store) => state.common);
    const {logout} = useCommonAction();

    useEffect(() => {
        if (errorLogin || !isLogged()) {
            logout(apolloClient);
        }
    }, [errorLogin, logout]);

    if (typeof errorLoginConfig === 'string') {
        // Show page with login disabled info
        return <LoginDisabled loginDisabledHtml={errorLoginConfig} />;
    }

    // component to show in index page.If we don't have any dashboard, is it empty page
    if (loginSuccess && isLogged()) {
        return <Div />;
    }

    return null;
};

export const getServerSideProps = async (ctx: NextPageContext) => {
    const {
        query: {error},
    } = ctx;

    if (error && typeof error === 'string') {
        return {
            redirect: {
                permanent: false,
                destination: `/login?error=${error.toUpperCase()}`,
            },
        };
    }

    return {
        props: {},
    };
};

export default Index;
